import React from "react";
import {
  Switch,
  Route,
} from "react-router-dom";
import Home from "./Home";
import SongContainer from './SongContainer';

export default function App() {
	return (
			<Switch>
				<Route path="/:id">
					<SongContainer />
				</Route>
				<Route path="/">
					<Home />
				</Route>
			</Switch>
	);
}