import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useKeyPress from '../hooks/useKeyPress';
import CountDownModal from './CountDownModal';
import { useParams } from "react-router-dom";
import { findById } from '../mockJson';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(6, 0, 3)
  },
  lightBulb: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1)
  },
  lyric: {
    display: "inline-block",
    whiteSpace: "pre"
  },
  countDownModal: {
    background: "rgba(255, 255, 255, 0)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: '100%',
    height: '100%'
  },
  nextCharacter: {
    background: "#A8A8A8",
    display: "inline-block",
    whiteSpace: "pre"
  }
}));

const typingInitialState = {
  chrIdx: -1,
  chrsTyped: [],
}

const IncorrectTextTypography = withStyles({
  root: {
    color: "#CC0000",
    textDecoration: "underline"
  }
})(Typography)


const CorrectTextTypography = withStyles({
  root: {
    color: "#008000"
  }
})(Typography)

const NextCharTypography = React.memo(({children, className, ...props}) => {
  const classes = useStyles();
  let nextCharacterRef = React.useRef();
  React.useEffect(() => {
    const rect = nextCharacterRef.current.getBoundingClientRect()
    if (rect.top > window.innerHeight - 20 || rect.top < 0) {
      nextCharacterRef.current.scrollIntoView(false);
    }
  }, [])

  return <Typography ref={nextCharacterRef} className={classes.nextCharacter} color={"textSecondary"}>{children}</Typography>
})

const LyricCharacter = React.memo(({ chr, id, chrsTyped }) => {
  const classes = useStyles()
  let CharTypography;

  if (id === chrsTyped.length) {
    CharTypography = NextCharTypography
  }
  else if (id >= chrsTyped.length) {
    CharTypography = Typography
  }
  else if (chrsTyped[id] === chr) {
    CharTypography = CorrectTextTypography
  } else {
    CharTypography = IncorrectTextTypography
  }

  return (<CharTypography className={classes.lyric} color={"textSecondary"}>
    {chr}
  </CharTypography>)
}, (props, nextProps) => {
  /** Significant pref boost avoiding render 
   * We only needs re-render a sliding window of 3 characters 
   * from where user needs to type
  */
  if (props.id === nextProps.chrsTyped.length - 1 ||
    (props.id === nextProps.chrsTyped.length) ||
    (props.id === nextProps.chrsTyped.length + 1)) {
    // Return false to re-render
    return false;
  } else {
    // Return true to avoid re-render
    return true
  }
})

function Lyrics({ lyrics }) {
  /** States: */
  const [typingState, setTypingState] = React.useState(typingInitialState);
  const [charsToType, setCharsToType] = React.useState([]);
  const [seconds, setSeconds] = React.useState(0);

  /** Refs: */
  const timer = React.useRef(null);
  const lyricsByLine = React.useRef(lyrics.split("\n"));

  /** Callbacks: */
  const startTimer = React.useCallback(() => {
    if (timer.current !== null) { return }

    // Start timer to keep track of WPM
    var start = Date.now();
    timer.current = setInterval(() => {
      var delta = Date.now() - start; // milliseconds elapsed since start
      setSeconds(delta / 1000);
    }, 1000) // update about every 100ms
  }, [])

  const stopTimer = React.useCallback(() => {
    clearInterval(timer.current);
    timer.current = null;
  }, [])

  const lineToChars = React.useCallback((line) => {
    return line.trim().concat(" ").split("").map(c => c);
  }, [])


  React.useEffect(() => {
    // Adding space to end of line so it doesn't randomly jump to next line on last char
    const chars = lyricsByLine.current.map(line => lineToChars(line)).flat()
    setCharsToType(chars);
  }, [lineToChars]);


  useKeyPress(key => {
    if (!timer.current) { return }
    // (can't optimize because we need to keep track of backtrace)
    if (key !== "Backspace") {
      setTypingState((prevTypingState) => ({ ...prevTypingState, chrsTyped: [...prevTypingState.chrsTyped, key] }))
    } else {
      setTypingState((prevTypingState) => ({ ...prevTypingState, chrsTyped: prevTypingState.chrsTyped.filter((_, i) => i !== prevTypingState.chrsTyped.length - 1) }))
    }
  })

  if (timer.current && charsToType.length <= typingState.chrsTyped.length) {
    stopTimer()
  }

  let chrCount = -1;

  const correct = typingState.chrsTyped.reduce((acc, chr, i) => {
    return chr === charsToType[i] ? acc + 1 : acc;
  }, 0)

  const accuracy = correct / typingState.chrsTyped.length * 100;
  const accuracyText = `${correct} / ${typingState.chrsTyped.length} (${(accuracy).toFixed(0)}%)`
  const wpm = (typingState.chrsTyped.length / 5) / (seconds / 60);

  return (
    <React.Fragment>
      <CountDownModal onClose={startTimer} />
      <Box>
        <Typography>{`Accuracy: ${isNaN(accuracy) ? '-' : accuracyText}`}</Typography>
        <Typography>{`WPM: ${isNaN(wpm) || !isFinite(wpm) ? '-' : (wpm).toFixed(0)}`}</Typography>
      </Box>
      {lyricsByLine.current.map((line, i) =>
        <Box key={i}>
          {
            lineToChars(line).map((chr) => {
              chrCount += 1
              return (
                <LyricCharacter chr={chr} key={chrCount} id={chrCount} chrsTyped={typingState.chrsTyped} />
              )
            })
          }
        </Box>
      )}
    </React.Fragment>
  )
}

export default function SongContainer() {
	let { id } = useParams();
	const song = findById(id);

	if (!song) {
		return <div>Did you edit the URL!? Can't find song id.</div>
	}
	
	return (
		<Container maxWidth="sm">
			<Box my={4}>
				<Typography variant="h4" component="h1">
					{song.songName}
				</Typography>
				<Typography variant="subtitle1" component="h6" gutterBottom>
					{song.artist}
				</Typography>
				<Lyrics lyrics={song.lyrics} />
			</Box>
		</Container>
	)
}