import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { songs } from '../mockJson'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
		flexDirection: 'column',
		flex: '1'
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
		width: 151,
		height: 151
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
	},
	rootGrid: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export function MediaControlCard({songName, artist, year, img}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {songName}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {artist}
          </Typography>
        </CardContent>
        <div className={classes.controls}>
					<Typography variant="caption" color="textSecondary">
							{year}
						</Typography>
        </div>
      </div>
      <CardMedia
        className={classes.cover}
        image={img}
        // title="Live from space album cover"
      />
    </Card>
  );
}

function createChunks(arr, size) {
	let result = [];

	for (var i = 0; i < arr.length; i += size) {
		result.push(arr.slice(i, i + size));
	}

	return result;
}


export function NestedGrid({songs}) {
	const chunks = createChunks(songs, 3);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} alignItems="center" justify="center">
				{chunks.map(row => (
					<Grid container item xs={12} spacing={3}>
						 {row.map(song => (
							 <Grid item xs={12} sm={4}>
								 <Link  component={RouterLink} to={song.id}>
										<MediaControlCard songName={song.songName} artist={song.artist} year={song.releaseYear} img={song.img}/>
									</Link>
							</Grid>
						 ))}
					</Grid>
				))}
      </Grid>
    </Container>
  );
}



export default function Home() {
	return (
    <NestedGrid songs={songs}/>
	)
}