import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    countDownModal: {
      background: "rgba(255, 255, 255, 0)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: '100%',
      height: '100%'
    },
    countDownText: {
      fontWeight: '500',
      color: '#008000'
    },
  }));

export default function CountDownModal({ onClose }) {
    const classes = useStyles();
    const [countdown, setCountdown] = React.useState(3);
    const [open, setOpen] = React.useState(true);
  
    React.useEffect(() => {
      var interval = setInterval(() => {
        // https://stackoverflow.com/questions/59144539/react-usestate-doesnt-update-state-on-setinterval
        setCountdown((prevCountdown) => {
          // Have countdown go down too -1 and then close modal
          if (prevCountdown < 1) {
            clearInterval(interval);
            setOpen(false);
            onClose()
          }
          return prevCountdown - 1
        });
      }, 1000)
  
      return () => {
        clearInterval(interval);
      }
    }, [onClose])
  
    const handleClose = () => {
      setOpen(false);
    };
  
  
    return (
      <Dialog fullScreen open={open} onClose={handleClose} PaperProps={{ style: { backgroundColor: 'transparent' } }}>
        <Box className={classes.countDownModal}>
          <Typography className={classes.countDownText} component="h1" variant="h1">{countdown > 0 ? countdown : "GO"}</Typography>
        </Box>
      </Dialog>
    )
  }